import React, { useEffect, useState } from "react";
import * as H from "history";
import {
  Button,
  Select,
  message,
  Input,
  Spin,
  Upload,
  Pagination,
  Popconfirm,
  Empty,
} from "antd";
import { BaseApi } from "../../requests/base-api";
import { showArticleModal, ModeType, ContentType } from "./add";
import { SearchOutlined, PlusOutlined } from "@ant-design/icons";
import Card from "./component/card";
import { showBatchImport } from "../batchImport/importArticle";
import { imageAddPrefix, formatContent } from "../../utils";
const { Option } = Select;

interface Props {
  history: H.History;
}
function Article(props: Props) {
  const { history } = props;
  const [loading, setLoading] = useState(false);
  const [labelList, setLabelList] = useState<any>([]);
  const [labelObject, setLabelObject] = useState<any>({});
  const [articleList, setArticleList] = useState<any>([]);
  const [label_id, setLabel_id] = useState<number | null>(null);
  const [total, setTotal] = useState<number | null>(0);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchText, setSearchText] = useState("");
  const [currentId, setCurrentId] = useState(0);
  const [currentInfo, setCurrentInfo] = useState<any>({});
  const [currentInfoContent, setCurrentInfoContent] = useState<any>([]);

  useEffect(() => {
    getallLabel();
  }, []);

  useEffect(() => {
    getallArticle();
  }, [page, pageSize, label_id, searchText]);
  const getallArticle = async () => {
    try {
      const res = await BaseApi.queryYunzuoArticleList({
        page,
        pageSize,
        searchText,
        label_id,
      });
      let { data, total } = res;
      data = data.map((item, index) => ({ ...item, index: index + 1 }));
      if (!data.length) {
        setCurrentFun();
      }
      if (page === 1 && data.length) {
        setCurrentFun(data[0]);
      }
      setArticleList(data);
      setTotal(total);
    } catch (error) {
      message.error(error.message);
    }
  };
  const setCurrentFun = (info?: any) => {
    if (!info) {
      setCurrentId(0);
      setCurrentInfo({});
      setCurrentInfoContent([]);
      return;
    }
    setCurrentId(info.id);
    setCurrentInfo(info);
    try {
      const arr = JSON.parse(formatContent(info.content));
      setCurrentInfoContent(arr);
    } catch (error) {
      setCurrentInfoContent([{ type: 2, content: info.content }]);
    }
  };
  const getallLabel = async () => {
    try {
      const res = await BaseApi.getallYunzuoType();
      const { data } = res;
      const obj: any = {};
      data.map((item: any) => {
        obj[item.id] = item;
      });
      setLabelObject(obj);
      setLabelList(res.data);
    } catch (error) {
      message.error((error as Error).message);
    }
  };

  const deleteArticle = async (id: number) => {
    try {
      const res = await BaseApi.deleteYunzuoArticle(id);
      if (res.statusCode === 200) {
        getallArticle();
      }
      message.info(res.msg);
    } catch (error) {
      message.error((error as Error).message);
    }
  };

  const callback = (key: any) => {
    console.log(key);
  };

  const addOrEditArticleFun = (param: {
    mode: ModeType;
    articleInfo?: any;
    labelList: any;
  }) => {
    const instance = showArticleModal({
      mode: param.mode,
      articleInfo: param.articleInfo,
      labelList: param.labelList,
      onClose: () => {
        instance.destory();
      },
      refresh: () => getallArticle(),
    });
  };

  const resetSearchParam = () => {
    setPage(1);
    setPageSize(10);
    setLabel_id(null);
    setSearchText("");
  };

  return (
    <div className="article-secpage-wrap">
      <div className="sec-header">
        <span className="sec-header-title">文章管理</span>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span style={{ whiteSpace: "nowrap", marginRight: 5 }}>类别:</span>
          <Select
            size="small"
            value={label_id}
            style={{ width: 100, marginRight: 10, fontSize: 12 }}
            onChange={(value) => {
              setLabel_id(value);
            }}
          >
            {Object.values(labelObject).map((item: any) => {
              return (
                <Option key={item.id} value={item.id}>
                  {item.roles}
                </Option>
              );
            })}
          </Select>
          <Input
            placeholder="输入关键词进行搜索"
            value={searchText}
            onChange={(e: any) => setSearchText(e.target.value)}
            style={{ marginRight: 5, fontSize: 12, width: 160 }}
            size="small"
          />
          <Button
            type="link"
            size="small"
            style={{ marginRight: 5 }}
            onClick={() => resetSearchParam()}
          >
            重置
          </Button>
          <Button
            icon={<SearchOutlined />}
            size="small"
            style={{ marginRight: 5 }}
            onClick={() => getallArticle()}
          >
            筛选
          </Button>
          <Button
            type="primary"
            size="small"
            icon={<PlusOutlined />}
            onClick={() =>
              addOrEditArticleFun({
                mode: ModeType.CREATE,
                labelList: Object.values(labelObject),
              })
            }
          >
            添加文章
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              const instance = showBatchImport({
                onClose: () => {
                  instance.destory();
                },
                refresh: () => getallArticle(),
              });
            }}
          >
            批量上传
          </Button>
          {/* <Button
            size="small"
            type="link"
            onClick={() => {
              const instance = showBatchImport({
                mode: MT.ARTICLE,
                onClose: () => {
                  instance.destory();
                },
                refresh: () => getallArticle(),
              });
            }}
          >
            批量导入
          </Button> */}
          {/* <Upload
            name="logo"
            listType="picture"
            beforeUpload={(file) => {
              uploadFile(file);
              return false;
            }}
          >
            <Button size="small" type="link">
              批量导入
            </Button>
          </Upload> */}
        </div>
      </div>
      <div className="secpage-content">
        <div className="content-list">
          <h5 className="title">列表</h5>
          <div className="list-wrap">
            {articleList.length ? (
              articleList.map((item: any) => (
                <Card
                  key={item.id}
                  active={item.id === currentId}
                  info={item}
                  onClick={() => {
                    setCurrentFun(item);
                  }}
                />
              ))
            ) : (
              <Empty style={{ marginTop: "10vh" }} />
            )}
          </div>
          <div className="pagination-div">
            <span style={{ fontSize: 12, marginRight: 5 }}>共{total}条</span>
            <span style={{ fontSize: 12, marginRight: 5 }}>
              每页{pageSize}条
            </span>
            <Pagination
              simple
              current={page}
              pageSize={10}
              total={total || 0}
              onChange={(page) => setPage(page)}
            />
          </div>
        </div>
        <div
          className="content-info"
          style={{ display: currentInfo.id ? "" : "none" }}
        >
          <div className="title">
            <div>
              <span style={{ fontSize: 16, marginRight: 10 }}>
                {currentInfo.title}
              </span>
            </div>
            <div className="edit-tool">
              <Button
                type="link"
                onClick={() => {
                  addOrEditArticleFun({
                    mode: ModeType.MODIFY,
                    labelList: Object.values(labelObject),
                    articleInfo: currentInfo,
                  });
                }}
              >
                编辑
              </Button>
              <Popconfirm
                title="是否删除?"
                onConfirm={() => deleteArticle(currentInfo.id)}
                onCancel={() => {}}
                okText="确定"
                cancelText="取消"
              >
                <Button type="link" size="small" danger>
                  删除
                </Button>
              </Popconfirm>
            </div>
          </div>
          <div className="label-box">
            {/* <span className="label-tag">
              {currentInfo.auth ? currentInfo.auth : "转载"}
            </span> */}
            <span style={{ margin: "0 10px" }}>{currentInfo.create_time}</span>
            <span className="label-content">
              {labelObject[currentInfo.label_id]?.roles}
            </span>
            {/* <span className="label-content">{currentInfo.yunzuolabelname}</span> */}
            {/* <span style={{ marginLeft: 10, fontWeight: "bold", color: "#333" }}>
              作者：{currentInfo.auth}
            </span> */}
            <span className="label-content" style={{ marginLeft: 15 }}>
              阅读量：{currentInfo.count || 0}
            </span>
          </div>
          <div className="info-wrap">
            <p className="info-sketch">{currentInfo.sketch}</p>
            {currentInfo &&
              currentInfoContent.map((item: any, index: number) => {
                console.log("zkf-item", item);
                return (
                  <div className="info-section-text" key={index}>
                    {item.type === ContentType.video ? (
                      <video
                        autoPlay
                        controls
                        className="info-section-img"
                        src={imageAddPrefix(item.content || "")}
                      />
                    ) : item.type === ContentType.image ? (
                      <img
                        alt="图片"
                        src={imageAddPrefix(item.content || "")}
                        className="info-section-img"
                      />
                    ) : (
                      item.content
                        .split("||||")
                        .map((item: any, index: number) => (
                          <div
                            className="text-box"
                            key={index}
                            dangerouslySetInnerHTML={{ __html: item }}
                          ></div>
                        ))
                    )}
                  </div>
                );
              })}
            {currentInfo.file ? (
              <div>
                <p>附件列表：</p>
                {currentInfo.file.split(",").map((item: any) => (
                  <a target="_blank" href={imageAddPrefix(item)}>
                    {item && item.split("/").slice(-1)}
                  </a>
                ))}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <div
        className="article-loading"
        style={{ display: loading ? "" : "none" }}
      >
        <Spin spinning={loading} tip="正在处理..."></Spin>
      </div>
    </div>
  );
}

export default Article;
