import React, { useEffect, useState } from "react";
import * as H from "history";
import "./card.scss";
import { CoverType } from "../add";
import { PlayCircleOutlined } from "@ant-design/icons";
import { imageAddPrefix, isAssetTypeAnImage } from "../../../utils";

interface Props {
  info: any;
  active: boolean;
  onClick: () => void;
}
function Card(props: Props) {
  const { info, active = false, onClick } = props;
  const { cover = "", title, sketch, labelname, roles, create_time } = info;
  let createTime = create_time.split(" ")[0];
  let coverList = (cover && cover.split(",")) || [];
  // const isImage = coverList.length !== 2;
  return (
    <div
      className={`article-card ${coverList.length > 2 ? "column" : ""}`}
      style={{ background: active ? "rgba(0,0,255,0.05)" : "" }}
      onClick={() => onClick()}
    >
      {coverList.length === 0 ? (
        <React.Fragment>
          <div className="card-right">
            <h5 className="cart-title">{title}</h5>
            {/* <h5 className="cart-sketch">{sketch}</h5> */}
            <h5 className="cart-label-box">
              <span className="cart-label-box-l">{roles}</span>
              <span>{createTime}</span>
            </h5>
          </div>
        </React.Fragment>
      ) : info.article_type === CoverType.video ? (
        <React.Fragment>
          <div className="card-left">
            <img alt="" src={imageAddPrefix(coverList[coverList.length - 1])} />
            <PlayCircleOutlined
              style={{
                position: "absolute",
                color: "white",
                transform: "scale(2)",
              }}
            />
          </div>
          <div className="card-right">
            <h5 className="cart-title">{title}</h5>
            {/* <h5 className="cart-sketch">{sketch}</h5> */}
            <h5 className="cart-label-box">
              <span className="cart-label-box-l">{roles}</span>
              <span>{createTime}</span>
            </h5>
          </div>
        </React.Fragment>
      ) : coverList.length === 1 ? (
        <React.Fragment>
          <div className="card-left">
            <img alt="" src={imageAddPrefix(coverList[0])} />
          </div>
          <div className="card-right">
            <h5 className="cart-title">{title}</h5>
            {/* <h5 className="cart-sketch">{sketch}</h5> */}
            <h5 className="cart-label-box">
              <span className="cart-label-box-l">{roles}</span>
              <span>{createTime}</span>
            </h5>
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div className="card-right">
            <h5 className="cart-title">{title}</h5>
            {/* <h5 className="cart-sketch">{sketch}</h5> */}
            <div className="card-left multi">
              {coverList.map((cover: string, index: number) => (
                <img alt="" key={cover} src={imageAddPrefix(cover)} />
              ))}
            </div>
            <h5 className="cart-label-box">
              <span className="cart-label-box-l">{roles}</span>
              <span>{createTime}</span>
            </h5>
          </div>
        </React.Fragment>
      )}
    </div>
  );
}

export default Card;
