import React, { useEffect, useState } from "react";
import ReactDom from "react-dom";
import { Select, message, Button, Modal, Upload } from "antd";
import { FileExcelOutlined, FileZipOutlined } from "@ant-design/icons";
import { BaseApi } from "../../requests/base-api";
import "./index.scss";
const { Dragger } = Upload;

interface Props {
  onClose: () => void; //的地方就不用重新请求列表
  refresh: () => void; //兼容拓展，在用到的地方就不用重新请求列表
}

function BatchImport({ onClose, refresh }: Props) {
  const [visible, setVisible] = useState(true);
  const [loading, setLoading] = useState(false);
  const [doc, setDoc] = useState<any>(null);

  useEffect(() => {}, []);
  const handleOk = async () => {
    if (!doc) {
      message.info("未选择excel文件，将只导入图片");
    }
    setLoading(true);
    await docImport();
  };

  const handleCancel = () => {
    shut();
  };

  const docImport = async () => {
    if (!doc) return;
    setLoading(true);
    try {
      const server = BaseApi.articleImportYunzuo;

      let formdata = new FormData();
      formdata.append("file", doc);
      const res = await server(formdata);
      if (res && res.msg) {
        message.info(res.msg);
        refresh();
      } else {
        message.error("导入失败");
      }
      setLoading(false);
      shut();
    } catch (error) {
      setLoading(false);
      message.error(error.message);
    }
  };

  const shut = () => {
    setVisible(false);
    setTimeout(() => onClose(), 2000);
  };
  return (
    <Modal
      title={`批量导入文章`}
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      okText="确定"
      cancelText="取消"
      footer={[
        <Button key="back" onClick={handleCancel}>
          取消
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loading}
          onClick={handleOk}
        >
          确定
        </Button>,
      ]}
    >
      <div className="batch-upload-item">
        <label>选择EXCEL文件：</label>
        <Dragger
          {...{
            name: "file",
            multiple: true,
            beforeUpload: (file) => {
              setDoc(file);
              return false;
            },
            onRemove: () => setDoc(null),
            onDrop(e) {
              console.log("zkf Dropped files", e.dataTransfer.files);
            },
          }}
        >
          <p className="ant-upload-drag-icon">
            <FileExcelOutlined />
          </p>
          <p className="ant-upload-text">单击或者拖拽文件到此处</p>
          <p className="ant-upload-hint">仅支持单个excel文件的上传</p>
        </Dragger>
      </div>
    </Modal>
  );
}

export const showBatchImport = (props: Props) => {
  const container = document.createElement("div");
  document.body.appendChild(container);
  ReactDom.render(<BatchImport {...props} />, container);
  return {
    destory: () => {
      ReactDom.unmountComponentAtNode(container);
      container.remove();
    },
  };
};
