import React, { useEffect, useState } from "react";
import ReactDom from "react-dom";
import * as H from "history";
import {
  Form,
  Select,
  Radio,
  message,
  Input,
  Modal,
  Button,
  Upload,
  Spin,
} from "antd";
import { BaseApi } from "../../requests/base-api";
import TextArea from "antd/lib/input/TextArea";
import { EQStatus } from "../../types";
import { FormInstance } from "antd/es/form";
import {
  UploadOutlined,
  DeleteOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
  PictureOutlined,
  FileWordOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { imageAddPrefix, formatContent } from "../../utils";
import RichText from "./component/richText";
import TagComp from "./tag";

const { Option } = Select;
export enum ModeType {
  CREATE = "create",
  MODIFY = "modify",
}
export enum CoverType {
  image = "image",
  video = "video",
  text = "text",
}
export enum ContentType {
  image = 1,
  video = 3,
  text = 2,
}
interface Props {
  mode: ModeType;
  articleInfo?: any;
  labelList: any;
  onClose: () => void; //的地方就不用重新请求列表
  refresh: () => void; //兼容拓展，在用到的地方就不用重新请求列表
}

function AddArticle({
  onClose,
  refresh,
  mode = ModeType.CREATE,
  articleInfo = {},
  labelList = [],
}: Props) {
  const [visible, setVisible] = useState(true);
  const [loading, setLoading] = useState(false);
  const [info, setInfo] = useState<any>({});
  const [yunzuoLabelList, setYunzuoLabelList] = useState<any>([]);
  const [coverList, setCoverList] = useState<any>([]);
  const [coverType, setCoverType] = useState<CoverType>(CoverType.text);
  const formRef = React.createRef<FormInstance>();

  useEffect(() => {
    getYunzuoLabelList();
    if (mode === ModeType.MODIFY) {
      contentInit(articleInfo);
    } else {
      formRef.current!.setFieldsValue({ covertype: CoverType.text });
    }
  }, []);

  const contentInit = (articleInfo: any) => {
    console.log("zkf---", articleInfo);
    try {
      const content = JSON.parse(formatContent(articleInfo.content));
      modifyInit(content);
    } catch (error) {
      const content = articleInfo.content;
      modifyInit(content);
    }
  };
  const modifyInit = (content: any) => {
    const _info = {
      ...articleInfo,
      content,
    };
    _info.file = _info.file ? _info.file.split(",") : [];
    formRef.current!.setFieldsValue(_info);
    setInfo(_info);
    const coverArr = _info.cover ? _info.cover.split(",") : [];
    let currentType = _info.article_type;
    setCoverType(currentType);
    setCoverList(coverArr);
    formRef.current!.setFieldsValue({
      covertype: currentType,
    });
  };
  const handleOk = () => {};

  const handleCancel = () => {
    shut();
  };

  const createArticle = async () => {
    try {
      const params = {
        ...info,
        content: formatContent(JSON.stringify(info.content)),
        imageUrl: "",
        time: moment().format("YYYY-MM-DD HH:mm:ss"),
        file: info.file && info.file.length ? info.file.join(",") : "",
        article_type: coverType,
      };
      // if (coverList.length) {
      //   params.cover = coverList.join(",");
      // }
      params.cover = coverList.length ? coverList.join(",") : "";
      if (coverType === CoverType.text) {
        params.cover = "";
      }
      // if (
      //   coverType === CoverType.video &&
      //   coverVideo.length &&
      //   coverVideoImage.length
      // ) {
      //   params.cover = `${coverVideo[0]},${coverVideoImage[0]}`;
      // }
      const res = await BaseApi.addYunzuoArticle(params);
      if (res.statusCode === 200) {
        refresh();
      }
      message.info(res.msg);
      shut();
    } catch (error) {
      message.error(error.message);
      shut();
    }
  };
  const getYunzuoLabelList = async () => {
    try {
      const res = await BaseApi.getYunzuoLabelList();
      if (res.statusCode === 200 && res.data) {
        setYunzuoLabelList(res.data);
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  const editArticle = async () => {
    try {
      editArticleFun(formatContent(JSON.stringify(info.content)));
    } catch (error) {
      editArticleFun(info.content);
    }
  };
  const editArticleFun = async (content: any) => {
    try {
      const params = {
        ...info,
        content,
        file: info.file && info.file.length ? info.file.join(",") : "",
        article_type: coverType,
      };

      params.cover = coverList.length ? coverList.join(",") : "";
      if (coverType === CoverType.text) {
        params.cover = "";
      }
      // if (coverType === CoverType.image && coverList.length) {
      //   params.cover = coverList.join(",");
      // }
      // if (
      //   coverType === CoverType.video &&
      //   coverVideo.length &&
      //   coverVideoImage.length
      // ) {
      //   params.cover = `${coverVideo[0]},${coverVideoImage[0]}`;
      // }
      const res = await BaseApi.updateYunzuoArticle(params);
      if (res.statusCode === 200) {
        refresh();
      }
      message.info(res.msg);
      shut();
    } catch (error) {
      message.error(error.message);
      shut();
    }
  };
  const shut = () => {
    setVisible(false);
    setTimeout(() => onClose(), 2000);
  };

  const onFinish = (values: any) => {
    if (coverType === CoverType.image && coverList.length === 2) {
      message.warn("封面只能1张、3张或不传，不能为2张。");
      return false;
    }
    if (coverType === CoverType.video && coverList.length >= 2) {
      message.warn("视频类型封面只能1张。");
      return false;
    }
    // if (
    //   coverType === CoverType.video &&
    //   (coverVideo.length < 1 || coverVideoImage.length < 1)
    // ) {
    //   message.warn("请上传视频以及视频封面。");
    //   return false;
    // }
    mode === ModeType.CREATE ? createArticle() : editArticle();
  };

  const onFinishFailed = (errorInfo: any) => {
    message.warn(errorInfo.errorFields[0].errors);
  };
  const onTypeChange = (e: any) => setInfo({ ...info, type: e.target.value });

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };
  const uploadFile = async (
    file: any,
    isCover: boolean,
    callback?: Function
  ) => {
    setLoading(true);
    try {
      let formdata = new FormData();
      formdata.append("file", file);
      const res = await BaseApi.uploadFile(formdata);
      setLoading(false);
      if (res.statusCode === 200) {
        if (isCover) {
          const cl = [...coverList, res.url];
          setCoverList(cl);
          setInfo({ ...info, cover: cl.join(",") });
          formRef.current!.setFieldsValue({
            cover: res.url,
          });
        } else {
          callback && callback(res.url);
        }
      }
    } catch (error) {}
  };
  const controlSort = (
    index: number,
    d: "up" | "down" | "del" | "edit",
    value?: string
  ) => {
    const { content } = info;
    if (d === "del" && content.length <= 1) {
      message.info("内容不能为空，请至少保留一条.");
      return;
    }
    if (d === "up") {
      const item = content[index];
      content[index] = content[index - 1];
      content[index - 1] = item;
    } else if (d === "down") {
      const item = content[index];
      content[index] = content[index + 1];
      content[index + 1] = item;
    } else if (d === "del") {
      content.splice(index, 1);
    } else if (d === "edit") {
      content[index].content = value;
    }
    setInfo({ ...info, content: content });
  };
  return (
    <Modal
      title={`${mode === ModeType.CREATE ? "新增" : "编辑"}文章`}
      visible={visible}
      width={1000}
      onOk={handleOk}
      onCancel={handleCancel}
      okText="确定"
      cancelText="取消"
      footer={null}
      maskClosable={false}
    >
      <Spin tip="Loading..." spinning={loading}>
        <Form
          name="basic"
          labelCol={{ span: 2 }}
          wrapperCol={{ span: 22 }}
          initialValues={{ ...info }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          ref={formRef}
        >
          <Form.Item
            label="标题"
            name="title"
            rules={[{ required: true, message: "标题不能为空!" }]}
          >
            <Input
              value={info.title}
              onChange={(e) => setInfo({ ...info, title: e.target.value })}
            />
          </Form.Item>
          {/* <Form.Item
            label="作者"
            name="auth"
            rules={[{ required: false, message: "" }]}
          >
            <Input
              value={info.auto}
              onChange={(e) => setInfo({ ...info, auth: e.target.value })}
            />
          </Form.Item> */}
          <Form.Item
            label="简述"
            name="sketch"
            rules={[{ required: true, message: "简述不能为空!" }]}
          >
            <TextArea
              rows={2}
              value={info.sketch}
              onChange={(e) => setInfo({ ...info, sketch: e.target.value })}
            />
          </Form.Item>
          {mode === ModeType.MODIFY && (
            <Form.Item
              label="标签"
              name="tag"
              rules={[{ required: false, message: "标签不能为空!" }]}
            >
              <TagComp aid={info && info.id} />
            </Form.Item>
          )}

          <Form.Item
            label="封面类型"
            name="covertype"
            rules={[{ required: true, message: "封面类型不能为空!" }]}
          >
            <Radio.Group
              onChange={(e) => {
                setCoverType(e.target.value);
              }}
              value={coverType}
              defaultValue={coverType}
            >
              <Radio value={CoverType.text}>文字</Radio>
              <Radio value={CoverType.image}>图片</Radio>
              <Radio value={CoverType.video}>视频</Radio>
            </Radio.Group>
          </Form.Item>
          {/* <Form.Item
            label="封面类型"
            name="covertype"
            rules={[{ required: true, message: "封面类型不能为空!" }]}
          >
            <Select
              value={coverType}
              onChange={(value) => {
                setCoverType(value);
              }}
              style={{ width: "100%" }}
            >
              <Option value={CoverType.text}>文字</Option>
              <Option value={CoverType.image}>图片</Option>
              <Option value={CoverType.video}>视频</Option>
            </Select>
          </Form.Item> */}
          {coverType !== CoverType.text ? (
            <Form.Item name="cover" label="封面" rules={[{ required: false }]}>
              <h5
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                {coverList.map((cover: any, index: number) => (
                  <div
                    style={{
                      width: 200,
                      height: 200,
                      marginRight: 5,
                      position: "relative",
                      background: "black",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    key={cover}
                  >
                    <img
                      style={{ maxWidth: "100%", maxHeight: "100%" }}
                      src={imageAddPrefix(cover || "")}
                    />
                    <Button
                      style={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        background: "rgba(0,0,0,0.5)",
                      }}
                      icon={<DeleteOutlined />}
                      type="link"
                      onClick={() => {
                        const cl = [...coverList];
                        cl.splice(index, 1);
                        setCoverList(cl);
                      }}
                    ></Button>
                  </div>
                ))}
              </h5>
              <Upload
                // name="logo"
                showUploadList={false}
                listType="picture"
                beforeUpload={(file) => {
                  uploadFile(file, true);
                  return false;
                }}
              >
                <Button
                  disabled={coverList.length >= 3}
                  icon={<UploadOutlined />}
                >
                  点击上传封面
                </Button>
              </Upload>
            </Form.Item>
          ) : (
            ""
            // <Form.Item
            //   name="cover"
            //   label="视频"
            //   rules={[{ required: false, message: "视频不能为空" }]}
            // >
            //   <h5
            //     style={{
            //       display: "flex",
            //       alignItems: "center",
            //       width: "100%",
            //     }}
            //   >
            //     {coverVideo.map((cover: any, index: number) => (
            //       <div
            //         style={{
            //           maxWidth: 200,
            //           maxHeight: 500,
            //           marginRight: 5,
            //           position: "relative",
            //         }}
            //       >
            //         <video
            //           autoPlay
            //           controls
            //           style={{ maxWidth: "100%", maxHeight: "100%" }}
            //           src={imageAddPrefix(cover || "")}
            //         />
            //         <Button
            //           style={{
            //             position: "absolute",
            //             top: 0,
            //             right: 0,
            //             background: "rgba(0,0,0,0.5)",
            //           }}
            //           icon={<DeleteOutlined />}
            //           type="link"
            //           onClick={() => {
            //             setCoverVideo([]);
            //             formRef.current!.setFieldsValue({
            //               cover: null,
            //             });
            //             // setInfo({ ...info, cover: [] });
            //           }}
            //         ></Button>
            //       </div>
            //     ))}
            //   </h5>
            //   <Upload
            //     name="logo"
            //     accept="video/*"
            //     showUploadList={false}
            //     beforeUpload={(file) => {
            //       uploadFile(file, true, (url: string) => {
            //         setCoverVideo([url]);
            //         formRef.current!.setFieldsValue({
            //           cover: url,
            //         });
            //         // setInfo({ ...info, cover: [url] });
            //       });
            //       return false;
            //     }}
            //   >
            //     <Button
            //       disabled={coverVideo.length >= 1}
            //       icon={<UploadOutlined />}
            //     >
            //       点击上传视频
            //     </Button>
            //   </Upload>
            // </Form.Item>
          )}
          {/* {coverType === CoverType.video && (
            <Form.Item
              name="coverVideoImage"
              label="视频封面"
              rules={[{ required: false, message: "视频封面不能为空" }]}
            >
              <h5
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                {coverVideoImage.map((cover: any, index: number) => (
                  <div
                    style={{
                      maxWidth: 200,
                      maxHeight: 500,
                      marginRight: 5,
                      position: "relative",
                    }}
                  >
                    <img
                      style={{ maxWidth: "100%", maxHeight: "100%" }}
                      src={imageAddPrefix(cover || "")}
                    />
                    <Button
                      style={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        background: "rgba(0,0,0,0.5)",
                      }}
                      icon={<DeleteOutlined />}
                      type="link"
                      onClick={() => {
                        setCoverVideoImage([]);
                        formRef.current!.setFieldsValue({
                          coverVideoImage: null,
                        });
                        // setInfo({ ...info, cover: [] });
                      }}
                    ></Button>
                  </div>
                ))}
              </h5>
              <Upload
                name="logo"
                showUploadList={false}
                listType="picture"
                beforeUpload={(file) => {
                  uploadFile(file, true, (url: string) => {
                    setCoverVideoImage([url]);
                    formRef.current!.setFieldsValue({
                      coverVideoImage: url,
                    });
                    // setInfo({ ...info, cover: [url] });
                  });
                  return false;
                }}
              >
                <Button
                  disabled={coverVideoImage.length >= 1}
                  icon={<UploadOutlined />}
                >
                  点击上传视频封面
                </Button>
              </Upload>
            </Form.Item>
          )} */}
          {/* <Form.Item
            label="内容"
            name="content"
            rules={[{ required: true, message: "所属内容不能为空!" }]}
          >
            <div
              style={{
                // background: "whitesmoke",
                padding: 5,
                borderRadius: 5,
              }}
            >
              {info?.content?.map((item: any, index: number) => {
                return (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      margin: "5px 0",
                      background: "#fdfbfb",
                      borderRadius: 5,
                    }}
                  >
                    {item.type === ContentType.video ? (
                      <video
                        autoPlay
                        controls
                        style={{ maxWidth: 400, maxHeight: 200 }}
                        src={imageAddPrefix(item.content || "")}
                      />
                    ) : item.type === ContentType.image ? (
                      <img
                        alt=""
                        style={{ maxWidth: 400, maxHeight: 200 }}
                        src={imageAddPrefix(item.content || "")}
                      />
                    ) : (
                      <TextArea
                        rows={2}
                        value={item.content}
                        style={{ maxWidth: "75%" }}
                        onChange={(e) =>
                          controlSort(index, "edit", e.target.value)
                        }
                      />
                    )}
                    <span>
                      {index === 0 ? (
                        ""
                      ) : (
                        <Button
                          type="link"
                          title="向上移动"
                          icon={<ArrowUpOutlined />}
                          onClick={() => controlSort(index, "up")}
                        />
                      )}
                      {index === info.content.length - 1 ? (
                        ""
                      ) : (
                        <Button
                          type="link"
                          title="向下移动"
                          icon={<ArrowDownOutlined />}
                          onClick={() => controlSort(index, "down")}
                        />
                      )}
                      <Button
                        type="link"
                        title="删除"
                        icon={<DeleteOutlined />}
                        onClick={() => controlSort(index, "del")}
                      />
                    </span>
                  </div>
                );
              })}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  background: "rgb(251,251,251)",
                  padding: 5,
                  borderRadius: 5,
                }}
              >
                <Button
                  type="link"
                  icon={<FileWordOutlined />}
                  onClick={() => {
                    const content = info.content || [];
                    content.push({ type: 2, content: "" });
                    setInfo({ ...info, content });
                  }}
                >
                  插入段落
                </Button>
                <Upload
                  // name="logo"
                  listType="picture"
                  showUploadList={false}
                  beforeUpload={(file) => {
                    uploadFile(file, false, (url: string) => {
                      const content = info.content || [];
                      content.push({ type: ContentType.image, content: url });
                      setInfo({ ...info, content });
                    });
                    return false;
                  }}
                >
                  <Button
                    style={{ marginLeft: 10 }}
                    type="link"
                    icon={<PictureOutlined />}
                  >
                    插入图片
                  </Button>
                </Upload>
                <Upload
                  // name="logo"
                  accept="video/*"
                  showUploadList={false}
                  beforeUpload={(file) => {
                    if (
                      info.content?.filter(
                        (item: any) => item.type === ContentType.video
                      ).length > 0
                    ) {
                      message.info("只能上传一个视频");
                    } else {
                      uploadFile(file, false, (url: string) => {
                        const content = info.content || [];
                        content.push({ type: ContentType.video, content: url });
                        setInfo({ ...info, content });
                      });
                    }
                    return false;
                  }}
                >
                  <Button
                    style={{ marginLeft: 10 }}
                    type="link"
                    icon={<PictureOutlined />}
                  >
                    插入视频
                  </Button>
                </Upload>
              </div>
            </div>
          </Form.Item> */}
          <Form.Item
            label="内容"
            name="content"
            rules={[{ required: true, message: "所属内容不能为空!" }]}
          >
            {((mode === ModeType.MODIFY && info?.content) ||
              mode === ModeType.CREATE) && (
              <RichText
                content={info?.content}
                setLoading={setLoading}
                onChange={(res: any) => {
                  console.log("zkf-res", res);
                  setInfo({
                    ...info,
                    content: res,
                  });
                }}
              ></RichText>
            )}
          </Form.Item>
          <Form.Item
            label="角色"
            name="label_id"
            rules={[{ required: true, message: "所属角色不能为空!" }]}
          >
            <Select
              value={info.label_id}
              onChange={(value) => {
                setInfo({ ...info, label_id: value });
              }}
              style={{ width: "100%" }}
            >
              {labelList.map((item: any) => {
                return (
                  <Option key={item.id} value={item.id}>
                    {item.roles}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          {/* <Form.Item
            label="场景"
            name="t_yunzuo_label_id"
            rules={[{ required: true, message: "所属场景不能为空!" }]}
          >
            <Select
              value={info.t_yunzuo_label_id}
              onChange={(value) => {
                setInfo({ ...info, t_yunzuo_label_id: value });
              }}
              style={{ width: "100%" }}
            >
              {yunzuoLabelList.map((item: any) => {
                return (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item> */}
          <Form.Item
            label="状态"
            name="status"
            rules={[{ required: true, message: "状态不能为空!" }]}
          >
            <Radio.Group
              onChange={(e) => setInfo({ ...info, status: e.target.value })}
              value={info.status}
            >
              <Radio value={EQStatus.ONLINE}>在线</Radio>
              <Radio value={EQStatus.OFFLINE}>下架</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label="备注"
            name="description"
            rules={[{ required: false, message: "备注可以为空!" }]}
          >
            <TextArea
              rows={2}
              value={info.description}
              onChange={(e) =>
                setInfo({ ...info, description: e.target.value })
              }
            />
          </Form.Item>
          <Form.Item label="附件" name="file" rules={[{ required: false }]}>
            <Upload
              maxCount={3}
              showUploadList={false}
              beforeUpload={async (file) => {
                let formdata = new FormData();
                formdata.append("file", file);
                const res = await BaseApi.uploadFile(formdata);
                if (res.statusCode === 200 && res.url) {
                  setInfo({
                    ...info,
                    file: info.file ? [...info.file, res.url] : [res.url],
                  });
                }
                return false;
              }}
            >
              <Button icon={<UploadOutlined />}>选择文件</Button>
            </Upload>
            {info.file && info.file.length
              ? info.file.map((item: any, index: number) => (
                  <div className="article-pdf-file-box" key={index}>
                    <a
                      style={{ wordBreak: "break-all" }}
                      href={window.systemConfig.imagePrefix + item}
                      target="_blank"
                    >
                      {item.split("/")[item.split("/").length - 1]}
                    </a>
                    <Button
                      icon={<DeleteOutlined />}
                      type="link"
                      onClick={() => {
                        const fileArr = info.file;
                        fileArr.splice(index, 1);
                        setInfo({ ...info, file: fileArr });
                        formRef.current!.setFieldsValue({
                          file: "",
                        });
                      }}
                    ></Button>
                  </div>
                ))
              : ""}
          </Form.Item>
          <div className="article-form">
            <Button
              style={{ marginRight: 17 }}
              type="default"
              onClick={() => shut()}
            >
              取消
            </Button>
            <Button type="primary" htmlType="submit">
              确定
            </Button>
          </div>
        </Form>
      </Spin>
    </Modal>
  );
}

export const showArticleModal = (props: Props) => {
  const container = document.createElement("div");
  document.body.appendChild(container);
  ReactDom.render(<AddArticle {...props} />, container);
  return {
    destory: () => {
      ReactDom.unmountComponentAtNode(container);
      container.remove();
    },
  };
};
